import { Box, Input } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
	LockOpenRounded,
	LockRounded,
	CheckRounded,
	CloseRounded,
	MusicNoteRounded,
	MusicOffRounded,
	VisibilityRounded,
	VisibilityOffRounded,
} from '../Icon'
import { containerStyles, toggledContainerStyles, switchStyles, toggledSwitchStyles } from './styles'

const icons = {
	lock: <LockRounded />,
	unlock: <LockOpenRounded />,
	check: <CheckRounded />,
	cross: <CloseRounded />,
	music_on: <MusicNoteRounded color="link" />,
	music_off: <MusicOffRounded color="link" />,
	visibility_on: <VisibilityRounded />,
	visibility_off: <VisibilityOffRounded />,
}

const Toggle = ({ value, onToggle, theme, id, ...rest }) => {
	const [toggled, setToggled] = useState(value)

	useEffect(() => {
		if (toggled != value) {
			setToggled(value)
		}
	}, [value])

	const handleToggle = (val) => {
		setToggled(val)
		onToggle(val)
	}

	let icon = <></>

	if (theme == 'visibility') {
		icon = toggled ? icons['visibility_on'] : icons['visibility_off']
	} else if (theme == 'lock') {
		icon = toggled ? icons['unlock'] : icons['lock']
	} else if (theme == 'check') {
		icon = toggled ? icons['check'] : icons['cross']
	} else if (theme == 'music') {
		icon = toggled ? icons['music_on'] : icons['music_off']
	}

	const container_styles = toggled ? { ...containerStyles, ...toggledContainerStyles } : containerStyles
	const switch_styles = toggled ? { ...switchStyles, ...toggledSwitchStyles } : switchStyles

	return (
		<Box sx={container_styles} onClick={handleToggle} {...rest}>
			<Input type="checkbox" id={id} checked={value} isReadOnly />
			<Box sx={switch_styles} tabIndex="0">
				{theme && icon}
			</Box>
		</Box>
	)
}

export default Toggle
