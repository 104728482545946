import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Card from '../../atoms/Card'
import { Box, Divider, Container, Heading, Text, Flex, Button, HStack, Icon, Stack } from '@chakra-ui/react'
import BackButton from '../../atoms/nav-controls/BackButton'
import { Helmet } from 'react-helmet'
import NavBar from '../../atoms/NavBar'
import { useEventContext } from '../../../contexts/EventProvider'
import { useEventHandlersV2 } from '../../../hooks/useEventHandlers'
import loadable from '@loadable/component'
import Toggle from '../../atoms/Toggle'
import usePagination from '../../../hooks/usePagination'
import Pagination from '../../molecules/Pagination'
import FilterButtons from '../../molecules/FilterButtons'
import useQueryAttribute from '../../../hooks/useQueryAttribute'
import FooterBase from '../../molecules/FooterBase'
import useScrollTopOnMount from '../../../hooks/useScrollTopOnMount'
import PlaylistProvider, { usePlaylistContext } from '../../../contexts/PlaylistProvider'
import { QueueMusicRounded } from '../../atoms/Icon'

const MusicGrid = loadable(() => import(/* webpackPrefetch: true */ './src/MusicGrid'), { ssr: false })

/**
 * Filters
 * */
const filterCollection = [
	{ value: 'all', label: 'All', colorScheme: 'blue' },
	{ value: 'custom', label: 'Uploads', colorScheme: 'teal' },
	{ value: 'acoustic', label: 'Acoustic', colorScheme: 'red' },
	{ value: 'ambient', label: 'Ambient', colorScheme: 'green' },
	{ value: 'country', label: 'Country', colorScheme: 'purple' },
	{ value: 'funk', label: 'Funk', colorScheme: 'orange' },
	{ value: 'piano', label: 'Piano', colorScheme: 'teal' },
	{ value: 'pop', label: 'Pop', colorScheme: 'yellow' },
	{ value: 'soul', label: 'Soul', colorScheme: 'cyan' },
	{ value: 'holidays', label: 'Holidays', colorScheme: 'pink' },
]

const SceneryMusic = () => {
	useScrollTopOnMount()
	const { event } = useEventContext()
	const eventHandlers = useEventHandlersV2(event.uuid)
	const { uuid } = useParams()
	const navigate = useNavigate()

	/**
	 * Retrieve state of the song library
	 * */
	const { tracks, trackIds } = usePlaylistContext()

	/**
	 * Hold reference to the query parameter
	 * */
	const filter = useQueryAttribute({ key: 'filter', defaultValue: 'all' })

	const [filteredCollection, setFilteredCollection] = useState([])

	useEffect(() => {
		if (tracks && tracks.length > 0) {
			if (filter) {
				const _collection =
					filter != 'all' ? tracks.filter((t) => t.genre.toUpperCase() === filter.toUpperCase()) : tracks

				setFilteredCollection(_collection)
			} else setFilteredCollection(tracks)
		}
	}, [filter, tracks])

	// const handleMusicFilter = (genre) => {
	// 	let filtered
	// 	if (genre === 'All') {
	// 		filtered = selectable
	// 	} else {
	// 		filtered = selectable.filter((track) => track.genre === genre)
	// 	}
	// 	setSelected(genre)
	// 	setTotalPages(Math.ceil(filtered.length / limit))
	// 	setCurrentPage(1)
	// 	setFilteredMusicLibrary(filtered)
	// 	setPaginatedMusicLibrary(filtered.slice(0, limit))
	// }
	/**
	 * Pagination + retreive slice of collection
	 */
	const paginationInstance = usePagination({ collection: filteredCollection })
	const { slice, handlers, pagination } = paginationInstance

	const handleScrollTop = (e) => {
		e.preventDefault()
		if (typeof window != 'undefined') window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	return (
		<>
			<Helmet>
				<title>VidDay - Video Theme</title>
			</Helmet>

			<NavBar position="fixed">
				<HStack spacing={['4%', '1rem']}>
					<BackButton onClick={() => navigate(eventHandlers.customize)} />
				</HStack>
			</NavBar>

			<Container maxW="container.xl" py="1rem" variant="main">
				<Box my="2rem" textAlign={['left', 'center']} mx={['1rem', 0]}>
					<Heading as="h2" size="xl" variant="hero">
						Add a Song
					</Heading>
				</Box>

				<Card px={['1rem', '2rem']} py="2rem" variant="main">
					{trackIds && trackIds.length > 0 && (
						<>
							<Flex alignSelf="center">
								<Button
									variant="link"
									onClick={() => navigate(eventHandlers.customize)}
									fontSize={['0.875rem']}>
									<QueueMusicRounded mr="0.25rem" /> {trackIds.length} song
									{trackIds.length > 1 && 's'} selected
								</Button>
							</Flex>
							<Divider my="2rem" borderStyle="dashed" />
						</>
					)}

					<FilterButtons
						collection={filterCollection}
						target={`/event/${uuid}/music`}
						activeValue={filter}
						keyName="filter"
						itemOnClick={handlers.goFirst}
					/>

					<MusicGrid collection={slice} />
				</Card>

				<Pagination pagination={pagination} handlers={handlers} my="2rem" />

				<Flex my="1rem" w="full" justify="center">
					<Button variant="ghost" onClick={handleScrollTop}>
						Back to Top
					</Button>
				</Flex>

				<FooterBase />
			</Container>
		</>
	)
}

const SceneryMusicRoot = () => {
	return (
		<PlaylistProvider>
			<SceneryMusic />
		</PlaylistProvider>
	)
}

export default SceneryMusicRoot
