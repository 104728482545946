import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Button, HStack, Box } from '@chakra-ui/react'

const FilterButtons = ({ collection, keyName, activeValue, target, itemOnClick }) => {
	const itemsView = collection.map((el, i) => {
		const { value, label, ...rest } = el

		const isActive = activeValue == el.value

		return (
			<Button
				key={i}
				w="auto"
				minW="120px"
				size="sm"
				as={RouterLink}
				to={`${target}?${keyName}=${value}`}
				variant={isActive ? 'solid' : 'outline'}
				onClick={!isActive ? itemOnClick : false}
				{...rest}>
				{label}
			</Button>
		)
	})

	return (
		<Box
			w={['calc(100% + 2rem)', 'full']}
			mx={['-1rem', 'inherit']}
			px="1rem"
			overflowX="auto"
			sx={{
				'pt': '0.2rem',
				'scrollbarWidth': 'none',
				'msOverflowStyle': 'none',
				'&::-webkit-scrollbar': {
					display: 'none',
				},
			}}>
			<HStack spacing={['.5rem', '1rem']} mb="2rem">
				{itemsView}
			</HStack>
		</Box>
	)
}

export default FilterButtons
