import { keyframes } from '@chakra-ui/react'

const slideRight = (distance) => keyframes`
    0% {
        left:0;
    }
    100% {
        left:calc(100% - ${distance});
    }
`
const slideLeft = (distance) => keyframes`
    0% {
        left:calc(100% - ${distance});
    }
    100% {
        left:0;
    }
`

export const containerStyles = {
	input: {
		display: 'none',
	},
	background: '#b5bcc3',
	borderRadius: '1rem',
	w: '2.5rem',
	minW: '2.5rem',
	h: '1rem',
	p: '0',
}

export const toggledContainerStyles = {
	background: '#04dfa0',
}

export const switchStyles = {
	h: '1.5rem',
	w: '1.5rem',
	pos: 'relative',
	bg: 'white',
	outline: '.25rem solid transparent',
	cursor: 'pointer',
	borderRadius: '20px',
	boxShadow: '1px 1px 3px rgb(0 0 0 / 25%)',
	top: '-4px',
	left: 0,
	animation: `${slideLeft('24px')} 0.3s ease-in-out forwards`,
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'center',
	_after: {
		padding: '16px',
		content: "''",
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	svg: {
		color: '#47596a',
		w: '1rem',
		h: '1rem',
		minW: '1rem',
		minH: '1rem',
	},
	_active: {
		outlineColor: 'blackAlpha.100',
	},
	_hover: {
		outlineColor: 'blackAlpha.100',
	},
	_focus: {
		outlineColor: 'blackAlpha.100',
	},
}

export const toggledSwitchStyles = {
	animation: `${slideRight('24px')} 0.3s ease-in-out forwards`,
	svg: {
		color: '#04dfa0',
		w: '1rem',
		h: '1rem',
		minW: '1rem',
		minH: '1rem',
	},
}
