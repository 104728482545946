import { useState, useEffect } from 'react'

const usePagination = ({ collection }) => {
	if (!Array.isArray(collection)) throw new Error('Pagination: collection must be an array.')

	const [pageSize, setPageSize] = useState(12)
	const [currentPage, setCurrentPage] = useState(0)
	const [numberOfPages, setNumberOfPages] = useState(0)
	const [slice, setSlice] = useState([])
	/** Hold collection of pages (array) */
	const [pages, setPages] = useState([])

	/**
	 * Compute the number of pages available
	 */
	useEffect(() => {
		setNumberOfPages(Math.ceil(collection.length / pageSize) - 1)
	}, [collection, pageSize])

	/**
	 * Compute array of items that match the current page and size
	 */
	useEffect(() => {
		const from = currentPage < 1 ? 0 : currentPage * pageSize
		const to = currentPage < 1 ? pageSize : (currentPage + 1) * pageSize
		setSlice(collection.slice(from, to))
	}, [collection, currentPage, pageSize])

	/**
	 * Update array of pages when the number of pages change
	 */
	useEffect(() => {
		numberOfPages && numberOfPages > 0 && setPages(Array.from(Array(numberOfPages)))
	}, [numberOfPages])

	const handleScrollTop = () => {
		if (typeof window != 'undefined') window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	const goFirst = () => {
		setCurrentPage(0)
		handleScrollTop()
	}
	const goLast = () => {
		setCurrentPage(numberOfPages)
		handleScrollTop()
	}
	const goTo = (i) => {
		setCurrentPage(i)
		handleScrollTop()
	}

	const goPrevious = () => {
		currentPage == 0 ? setCurrentPage(0) : setCurrentPage(currentPage - 1)
		handleScrollTop()
	}

	const goNext = () => {
		currentPage == numberOfPages ? setCurrentPage(numberOfPages) : setCurrentPage(currentPage + 1)
		handleScrollTop()
	}

	return {
		slice: slice,
		pagination: {
			pageSize: pageSize,
			currentPage: currentPage,
			numberOfPages: numberOfPages,
			lastPage: numberOfPages,
			pages: pages,
		},
		// Handlers
		handlers: {
			goFirst,
			goLast,
			goTo,
			goNext,
			goPrevious,
		},
	}
}

export default usePagination
